<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Les boutiques</ion-title>
        <ion-buttons slot="end">
<!--         <ion-fab-button size="small" slot="start">
        <ion-icon :icon="add" @click="lister()"></ion-icon>
</ion-fab-button> -->
      </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <DynBase obj="menus" action="lister"/>
     <ion-searchbar showcancelbutton="" :value="recherche" @ionInput="rechercher($event)"></ion-searchbar>
<ion-card  v-for="mod in laListe.value" :key="mod._id" v-if="ok">
<!-- :router-link="'/jsc/Boutique/'+mod._id"> -->




  <ion-item>
<ion-avatar>
<img :src="mod.photo" v-if="mod.photo" class="full-image" />
</ion-avatar>
      <ion-card-subtitle>{{mod.nom}} </ion-card-subtitle>
          <ion-button fill="outline" slot="end" @click="rdv(mod)">Prendre RdV</ion-button>
          <ion-button fill="outline" slot="end" :router-link="'/jsc/Boutique/'+mod._id">Voir</ion-button>
    </ion-item>
    <ion-card-header>
      <ion-item  v-if="mod.photo">
      <ion-thumbnail>
      <img :src="mod.photo"  />
        </ion-thumbnail>
      </ion-item>
    </ion-card-header>

    <ion-card-content>
      <ion-item>
        <ion-label>Description</ion-label>
        <ion-text>{{mod.description}}</ion-text>
      </ion-item>
   </ion-card-content>
  </ion-card>

  </ion-content>
  </ion-page>
</template>
<script>

import {  archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, add } from 'ionicons/icons';


import {ref, computed, watch, reactive, onMounted, h} from 'vue'

  import { useRoute, useRouter } from 'vue-router';
import shared from "@/shared/shared";
import DynBase from "@/views/dynamique/DynBase";



export default {
  name: "BoutiqueLister",
  components: {DynBase},
  inject:{shared: "shared"},
  setup(props, context){

    const router = useRouter();
    const route = useRoute();
    const pageActuelle = ref(route.params.categorie || 'menus');
    const typeUrl = ref(route.params.categorie || 'modele');

    const section = ref(null)
    section.value = h('h1',"toto")
    //je dois charger la structure de la page si je ne l'ai pas dans le store

   // return () =>h('h1',"toto");
const ok=ref(false)
const recherche=ref("toto")
 const lister = () => {
      shared.localdb.find({
            selector: {
                type: 'boutique'
            }
        })
        .then((result) => {
            //alert("la")
            laListe.value=result.docs
            ok.value=true
        })
        .catch((err) => {
            alert(err);
        });


 }
  const laListe = reactive({})
//alert(JSON.stringify(shared))

const rechercher = async (evt) => {
 const searchterm = evt.srcElement.value;
 if (!searchterm){
   return;
 }
 laListe.value = laListe.filter(current => {
   return (current.nom.toLowerCase().indexOf(searchterm.toLowerCase())>1)
 })
}

   const rdv = (item) =>{
  //je dois mettre l objet par defaut et ensuire rediriger avec un _id null
     //alert('dans add')
     //alert(JSON.stringify(item))
     //shared.selectedId= null;
     //shared.selectedItem = shared.lemodele.value.defaultItem
     router.push('/jsc/boutique/'+item._id+'/rdv')


   };

   const jscadd = (item) =>{
  //je dois mettre l objet par defaut et ensuire rediriger avec un _id null
     //alert('dans add')
     //alert(JSON.stringify(item))
     shared.selectedId= null;
     shared.selectedItem = shared.lemodele.value.defaultItem
     router.push('/mods/'+item.type+'/Create')


   };
lister();

return {
 //computed
//function
route,
  section,
router,
 laListe,
 rdv,
  ok,
add,
lister,
rechercher,
recherche
//add-circle-outline,
}
}
}
</script>

<style scoped>
.image--size {
  width: 100%;
  height: 65.66px;
  object-fit: cover;
}
</style>
