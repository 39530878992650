<template>
  <div v-if="!modele">
    <div class="ion-padding custom-skeleton">
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 88%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
    </div>
  </div>
  <div v-else>
 <!-- <ion-button @click="jechange()">oki</ion-button>
  <div ref="titi"></div>-->
  {{modele}}
    <ion-list>
      <ion-item v-for="mod in modele" :key="mod._id">
        <ion-label>{{mod.title}}</ion-label>
      </ion-item>
    </ion-list>
 <!-- <component :is="tutu" :key="tutu"></component> -->
  </div>
</template>
<script>
import {useRoute, useRouter} from "vue-router";
import {ref, h} from 'vue'
import shared from "@/shared/shared";

export default {
  name: "DynBase",
  inject: {shared: "shared"},
  props:{
    obj:String,
    action:String
  },
   setup(props, context) {

    const router = useRouter();
    const route = useRoute();
    const pageActuelle = ref(route.params.categorie || 'menus');
    const typeUrl = ref(route.params.categorie || 'modele');

    const config = {

    }
    const modele=ref(null);
    shared.findByType(props.obj).then((result) => {
      setTimeout(() => {
        modele.value=result.docs
      }, 5000);
          //modele.value=result.docs

        })
        .catch((err) => {
          alert(err);
        });
    const titi = ref(null)
    titi.value = h('div', {
      ref: titi
    })
    const tutu = ref(null)
    tutu.value = h('h1', "AAAAAHHHHHHHHHHHHHHH")
//    section.value = h('ion-item', "toto")
    //je dois charger la structure de la page si je ne l'ai pas dans le store
    const test = [
        'ion-label',
        'ion-input'
    ]
const jechange = () => {
      tutu.value = h('h1', "BBBBBB")
    };

     return {
      titi,
       tutu,
       jechange,
       modele
     }

  }
}
</script>

<style scoped>

</style>
